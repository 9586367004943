import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import "./breadcrumbs.scss"
import { useIntl } from "gatsby-plugin-react-intl"

const BreadcrumbsListingExp = ({pageTile, pageUrl}) => {
  const intl = useIntl()

  return (
    <>
      <section className="breadcrumb">
        <Container>
          <Row>
            <Col>
              <ul>
                <li>
                  <Link
                    to={intl.locale == "en" ? "/en" : "/"}
                  >
                    {intl.locale == "en" ? "Home" : "Accueil"}
                  </Link>
                </li>
                <li>
                  <Link to={intl.locale == "en" ? "/en/plan-stay-mauritius" : "/planifier-sejour-ile-maurice"}>
                    {intl.locale == "en" ? "Plan your stay" : "Planifier votre sejour"}
                  </Link>
                </li>
                <li>
                  <Link to={intl.locale == "en" ? `/en${pageUrl}` : `${pageUrl}`}>
                    {pageTile}
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default BreadcrumbsListingExp
