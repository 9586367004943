import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageIntro from "../components/common/pageIntro/pageIntro"
import { graphql } from "gatsby"
import MainBannerImage from "../components/common/mainBanner/mainBannerImage"
import ChoixHotels from "../components/common/choixHotels/choixHotels"
import PushExperience from "../components/experience/pushExperience"
import { useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"
import { getCarouselImageOG } from "../components/functions"
import BreadcrumbListingExp from "../components/common/breadcrumbs/breadcrumbListingExp";
import Breadcrumbs from "../components/common/breadcrumbs/breadcrumbs"
import GenericComponents from "../components/generic/genericComponents/genericComponents"
import BlockBooking from "../components/common/blockBooking/blockBooking"
import { generateUrlByLocale } from "../services/intl.service"

export default function Experience({
     data: { nodeExperienceArticle },
     pageContext,
   }) {
  const {
    drupal_id,
    title,
    path: {
      alias: link,
    },
    is_collectable_experience,
    field_seo,
    relationships: {
      experience: taxonomyExperience,
      experience_article_introduction: pageIntro,
      experience_article_featured_land: featuredImageLan,
      carousel_highlight: {
        relationships: { carousel_highlight_item: carouselMea },
      },
      experience_article_components: components,
      push_experience: experiences,
      exp_article_block_resorts_villas: hotels,
    },
  } = nodeExperienceArticle

  
  const featuredImage = featuredImageLan?.relationships.featured_media_media?.relationships.field_image.gatsbyImage
  const intl = useIntl()
  const componentsFiltered = components.filter(
    value => Object.keys(value).length !== 0
  )

  const carouselFiltered = carouselMea.filter(
    carousel => carousel.relationships.media !== null
  )

  const seoData = setSeoData({
    lang: intl.locale,
    title: title,
    field_seo: field_seo,
    image: process.env.GATSBY_URL + getCarouselImageOG(carouselFiltered),
  })

  return (
    <Layout pageContext={pageContext} pageTitle={title} pageClass={is_collectable_experience == true ? "expArticleCollectible" : "expArticle"}>
      <Seo data={seoData} />
      {carouselFiltered.length > 0 && (
        <MainBannerImage items={carouselFiltered} />
      )}
        {is_collectable_experience ? 
        (<BreadcrumbListingExp pageTile={title} pageUrl={link}/>)
        :(<Breadcrumbs
          hotelTaxonomyDrupalId={pageContext.hotelTaxonomyDrupalId}
          pageTitle={title}/>
        )}
      {pageIntro && <PageIntro slice={pageIntro} />}
      <GenericComponents collectEx={is_collectable_experience} pageTitle={title} featuredImage={featuredImage} drupalId={drupal_id} pageContext={pageContext} experiences={experiences} isCollectibleExp={is_collectable_experience} intro={pageIntro} components={componentsFiltered} />
      {(is_collectable_experience != true && experiences) && (
        <PushExperience experiences={experiences} />
      )}
      <ChoixHotels content={hotels} />
      <BlockBooking hotel="verandaResorts" />
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    nodeExperienceArticle(id: { eq: $id }) {
      ...experienceArticleFields
    }
  }
`
