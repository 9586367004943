import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import Slider from "react-slick"
import "../../../assets/scss/plugins/_slick.scss"
import "./choixHotels.scss"
import StyledButton from "../../common/styledButton"
import parse from "html-react-parser"
import { Link } from "gatsby"
import { generateUrlByLocale } from "../../../services/intl.service"
import { useIntl } from "gatsby-plugin-react-intl"
import Heading from "../heading"
import HTMLReactParser from "html-react-parser";

const ChoixHotels = ({ content }) => {
  const intl = useIntl()

  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()

  const data = useStaticQuery(graphql`
    query blockResortsQuery {
      blkResortsEn: blockContentBlockResortsVillas(langcode: { eq: "en" }) {
        block_resorts_villas_caption
        block_resorts_villas_description {
          processed
        }
        block_resorts_villas_link {
          title
          uri
        }
        block_resorts_villas_title
        relationships {
          block_resorts_villas_items {
            featured_cta_text
            title
            hotel_featured_description {
              processed
            }
            hotel_featured_title
            path {
              alias
              langcode
            }
            relationships {
              hotel_featured_media_portrait {
                ...getFeaturedMedia
              }
              hotel_featured_media_landscape {
                ...getFeaturedMedia
              }
              hotel_map {
                relationships {
                  ...getImage
                }
              }
            }
          }
        }
      }
      blkResortsFr: blockContentBlockResortsVillas(langcode: { eq: "fr" }) {
        block_resorts_villas_caption
        block_resorts_villas_description {
          processed
        }
        block_resorts_villas_link {
          title
          uri
        }
        block_resorts_villas_title
        relationships {
          block_resorts_villas_items {
            featured_cta_text
            title
            hotel_featured_description {
              processed
            }
            hotel_featured_title
            path {
              alias
              langcode
            }
            relationships {
              hotel_featured_media_portrait {
                ...getFeaturedMedia
              }
              hotel_featured_media_landscape {
                ...getFeaturedMedia
              }
              hotel_map {
                relationships {
                  ...getImage
                }
              }
            }
          }
        }
      }
    }
  `)

  // Get Resorts data by locale
  let dataResorts
  switch (intl.locale) {
    case "fr":
      if (data.blkResortsFr) {
        dataResorts = data.blkResortsFr
      }
      break
    default:
      if (data.blkResortsEn) {
        dataResorts = data.blkResortsEn
      }
  }

  const villas = dataResorts?.relationships?.block_resorts_villas_items
    ? dataResorts.relationships.block_resorts_villas_items
    : []

  const hotelNames = villas.map(function (hotel) {
    return hotel.title
  })

  const settingsImg = {
    infinite: true,
    cssEase: "ease",
    pauseOnHover: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    customPaging: function (i) {
      return <span>{hotelNames.length > 0 ? HTMLReactParser(hotelNames[i].replace(/Veranda/g, "<em>Veranda</em>")) : ""}</span>
    },
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          centerPadding: "30px",
          dots: false,
        },
      },
    ],
  }

  const settingsText = {
    infinite: true,
    cssEase: "ease",
    pauseOnHover: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: false,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          centerPadding: "30px",
          adaptiveHeight: true,
        },
      },
    ],
  }

  const listImages = villas.map((list, index) => (
    <div
      key={index}
      className="blk-slide-img elem-clickable"
    >
      <Row>
        <Col md={8} xs={12}>
          <div className="animImgRight">
            <Link to={generateUrlByLocale(list.path.langcode, list.path.alias)}>
              <GatsbyImage
                image={getImage(
                  list.relationships.hotel_featured_media_landscape?.relationships
                    .featured_media_media?.relationships.field_image.gatsbyImage
                )}
                alt={
                  list.relationships.hotel_featured_media_landscape?.relationships
                    .featured_media_media?.field_image.alt
                }
              />
            </Link>
          </div>
        </Col>
        <Col md={4} xs={12}>
          <div className="animImgRight">
            <Link to={generateUrlByLocale(list.path.langcode, list.path.alias)}>
              <GatsbyImage
                image={getImage(
                  list.relationships.hotel_featured_media_portrait?.relationships
                    .featured_media_media?.relationships.field_image.gatsbyImage
                )}
                alt={
                  list.relationships.hotel_featured_media_portrait?.relationships
                    .featured_media_media?.field_image.alt
                }
              />
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  ))

  const listDescription = villas.map((list, index) => (
    <div
      key={index}
    >
      <Row key={index} className="blk-choix_details">
        <Col md={9} xs={12}>
          <div className="choix-content d-flex">
            <div className="choix-count d-flex flex-row">
              <div className="choix-count-current">{index + 1}</div>
              <div className="choix-count-total">{"/" + villas.length}</div>
            </div>
            <span className="title-mob">{list.title}</span>
            <div className="choix-info">
              <h3 className="font-style_2">{list.hotel_featured_title}</h3>
              {parse(list.hotel_featured_description.processed)}
              <div className="choix-link">
                <StyledButton
                  title={
                    list.featured_cta_text
                      ? list.featured_cta_text
                      : intl.formatMessage({ id: "explore" })
                  }
                  type="transparent"
                  path={generateUrlByLocale(list.path.langcode, list.path.alias)}
                />
              </div>
            </div>
          </div>
        </Col>
        <Col md={3} xs={12}>
          <Link to={generateUrlByLocale(list.path.langcode, list.path.alias)}>
            <GatsbyImage
              image={getImage(
                list.relationships.hotel_map?.relationships
                  .field_image?.gatsbyImage
              )}
              alt={list.hotel_featured_title}
            />
          </Link>
        </Col>
      </Row>
    </div>
  ))

  let title;

  if (content) {
    switch (content.block_resorts_villas_title) {
      case null:
        title = dataResorts.block_resorts_villas_title
        break;
      case "":
        title = dataResorts.block_resorts_villas_title
        break;
      default:
        title = content.block_resorts_villas_title
        break;
    }
  }
  return (
    <section
      className="blk-choix-hotels"
    //style={{ backgroundImage: "url(" + bgImage + ")" }}
    >
      {/* {bgImage && (
        <span
          className="cover-bg"
          style={{
            backgroundImage: "url(" + bgImage + ")",
          }}
        ></span>
      )} */}
      {dataResorts && <Container className="pt-100">
        <Row className="blk-choix-intro justify-content-center">
          <Col md={6} xs={12}>
            <Heading
              title={title}
              caption={dataResorts.block_resorts_villas_caption}
              description={parse(dataResorts.block_resorts_villas_description.processed)}
            />
          </Col>
          <Col md={6} xs={12} className="cta-intro">
            {/* {dataResorts.block_resorts_villas_link && (
              <StyledButton
                title={dataResorts.block_resorts_villas_link.title}
                type="link"
                path={dataResorts.block_resorts_villas_link.url}
              />
            )} */}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Slider
              {...settingsImg}
              asNavFor={nav2}
              ref={slider1 => setNav1(slider1)}
              className="blk-slider-img"
            >
              {listImages}
            </Slider>
          </Col>
          <Col xs={12} className="villa-infos">
            <Slider
              {...settingsText}
              asNavFor={nav1}
              ref={slider2 => setNav2(slider2)}
            >
              {listDescription}
            </Slider>
          </Col>
        </Row>
      </Container>
      }
    </section>
  )
}

export const query = graphql`
  fragment getHotels on paragraph__block_resorts_villas {
    internal {
      type
    }
    block_resorts_villas_title
  }
`

export default ChoixHotels
